var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "printingJoin" }, [
    _c("img", {
      attrs: { src: require("../../assets/img/u1331.png"), alt: "" }
    }),
    _c(
      "div",
      { staticClass: "join-content" },
      [
        _c(
          "el-form",
          {
            ref: "form",
            attrs: { model: _vm.form, rules: _vm.rules, "label-width": "120px" }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "手机：", prop: "phone" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入您的手机号" },
                  model: {
                    value: _vm.form.phone,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "phone", $$v)
                    },
                    expression: "form.phone"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "短信验证码：", prop: "code" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入收到的短信验证码" },
                  model: {
                    value: _vm.form.code,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "code", $$v)
                    },
                    expression: "form.code"
                  }
                }),
                _vm.isSend
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.getCode }
                      },
                      [_vm._v("获取验证码")]
                    )
                  : _c(
                      "el-button",
                      {
                        staticClass: "sendCode",
                        attrs: { type: "primary", disabled: "" }
                      },
                      [_vm._v(_vm._s(this.countdown + "s"))]
                    )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "企业名称：", prop: "companyName" } },
              [
                _c("el-input", {
                  attrs: {
                    placeholder: "请输入营业执照上的企业名称",
                    maxlength: "50"
                  },
                  model: {
                    value: _vm.form.companyName,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "companyName", $$v)
                    },
                    expression: "form.companyName"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "姓名：", prop: "nickname" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入您的姓名", maxlength: "20" },
                  model: {
                    value: _vm.form.nickname,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "nickname", $$v)
                    },
                    expression: "form.nickname"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "业务范围：", prop: "businessScope" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      multiple: "",
                      "collapse-tags": "",
                      placeholder: "请选择"
                    },
                    model: {
                      value: _vm.form.businessScope,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "businessScope", $$v)
                      },
                      expression: "form.businessScope"
                    }
                  },
                  _vm._l(_vm.businessList, function(item, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: {
                        label: item.taxonomyName,
                        value: item.taxonomyName
                      }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "其他：", prop: "others" } },
              [
                _c("el-input", {
                  attrs: {
                    placeholder: "可以输入您提供的其他业务范围",
                    type: "textarea",
                    rows: 5,
                    resize: "none",
                    maxlength: "200"
                  },
                  model: {
                    value: _vm.form.others,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "others", $$v)
                    },
                    expression: "form.others"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c("p", { staticClass: "agreement" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.isAgreen,
                expression: "isAgreen"
              }
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.isAgreen)
                ? _vm._i(_vm.isAgreen, null) > -1
                : _vm.isAgreen
            },
            on: {
              change: function($event) {
                var $$a = _vm.isAgreen,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.isAgreen = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.isAgreen = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.isAgreen = $$c
                }
              }
            }
          }),
          _vm._v(" 我阅读并同意 《无限立方用户协议》 ")
        ]),
        _c(
          "p",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isAgreen,
                expression: "!isAgreen"
              }
            ],
            staticClass: "tips"
          },
          [_vm._v("请阅读并同意《无限立方用户协议》")]
        ),
        _c(
          "div",
          {
            staticClass: "btn",
            staticStyle: { "text-align": "center", "margin-top": "20px" }
          },
          [
            _c(
              "el-button",
              {
                staticStyle: { width: "30%" },
                attrs: { type: "primary" },
                on: { click: _vm.submit }
              },
              [_vm._v("同意并注册")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }