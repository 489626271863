<template>
  <!-- 印刷厂加盟 -->
  <div class="printingJoin">
    <img src="../../assets/img/u1331.png" alt="" />
    <div class="join-content">
      <el-form :model="form" :rules="rules" ref="form" label-width="120px">
        <el-form-item label="手机：" prop="phone">
          <el-input
            placeholder="请输入您的手机号"
            v-model="form.phone"
          ></el-input>
        </el-form-item>
        <el-form-item label="短信验证码：" prop="code">
          <el-input
            placeholder="请输入收到的短信验证码"
            v-model="form.code"
          ></el-input>
          <el-button type="primary" @click="getCode" v-if="isSend"
            >获取验证码</el-button
          >
          <el-button type="primary" class="sendCode" disabled v-else>{{
            this.countdown + "s"
          }}</el-button>
        </el-form-item>
        <el-form-item label="企业名称：" prop="companyName">
          <el-input
            placeholder="请输入营业执照上的企业名称"
            v-model="form.companyName"
            maxlength="50"
          ></el-input>
        </el-form-item>
        <el-form-item label="姓名：" prop="nickname">
          <el-input
            placeholder="请输入您的姓名"
            v-model="form.nickname"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item label="业务范围：" prop="businessScope">
          <el-select
            v-model="form.businessScope"
            multiple
            collapse-tags
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in businessList"
              :key="index"
              :label="item.taxonomyName"
              :value="item.taxonomyName"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="其他：" prop="others">
          <el-input
            placeholder="可以输入您提供的其他业务范围"
            type="textarea"
            :rows="5"
            resize="none"
            v-model="form.others"
            maxlength="200"
          ></el-input>
        </el-form-item>
      </el-form>
      <p class="agreement">
        <input type="checkbox" v-model="isAgreen" />
        我阅读并同意 《无限立方用户协议》
      </p>
      <p class="tips" v-show="!isAgreen">请阅读并同意《无限立方用户协议》</p>
      <div class="btn" style="text-align: center; margin-top: 20px">
        <el-button type="primary" style="width: 30%" @click="submit"
          >同意并注册</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getBusiness, register } from '@/api/printing'
import { getPhoneCode } from '@/api/login'
import { setLocal } from '@/lib/local'
import { mapMutations } from 'vuex'

export default {
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'))
      } else {
        var reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的手机号'))
        }
      }
    }
    var validateCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入短信验证码'))
      } else {
        var reg = /^\d{6}$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的短信验证码'))
        }
      }
    }
    return {
      isSend: true,
      countdown: '',
      isAgreen: true,
      form: {
        phone: '',
        code: '',
        companyName: '',
        nickname: '',
        businessScope: ['数码印刷'],
        others: ''
      },
      rules: {
        phone: [
          { required: true, validator: validatePass, trigger: 'blur' }
        ],
        code: [
          { required: true, validator: validateCode, trigger: 'blur' }
        ],
        companyName: [
          { required: true, message: '请输入企业名称', trigger: 'blur' }
        ],
        nickname: [
          { required: true, message: '请输入您的姓名', trigger: 'blur' }
        ],
        businessScope: [
          { type: 'array', required: true, message: '请选择业务范围', trigger: 'change' }
        ]
      },
      businessList: [{ taxonomyName: '数码印刷' }]
    }
  },
  mounted () {
    this.getScope()
  },
  methods: {
    ...mapMutations(['setUserInfo']),
    // 获取业务范围
    async getScope () {
      const res = await getBusiness({ id: 39 })
      if (res.code == 0) {
        this.businessList = [...res.data[0].childList, ...this.businessList]
      }
    },
    // 获取验证码
    async getCode () {
      const params = JSON.stringify(this.form)
      if (!this.form.phone) {
        this.$message('请输入正确的手机号')
      } else {
        const res = await getPhoneCode(params)
        if (res && res.code === 0) {
          this.isSend = false
          this.countdown = 60
          const codeId = setInterval(() => {
            if (this.countdown === 0) {
              clearInterval(codeId)
              this.isSend = true
            }
            this.countdown--
          }, 1000)
        }
      }
    },
    // 注册
    submit () {
      if (this.isAgreen) {
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            const form = JSON.parse(JSON.stringify(this.form))
            form.businessScope.push(form.others)
            form.businessScope = form.businessScope.toString()
            const res = await register(form)
            if (res.code === 0) {
              this.$message.success('登录成功')
              setLocal(res.data.token)
              this.setUserInfo()
              this.$router.push('/plantWorkbench/orderConfirm')
            } else if (res.code == 500) {
              this.$message.error(res.msg)
              this.isSend = true
            }
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.printingJoin {
  margin: 30px auto;
  width: 60%;
  border: 1px solid #ccc;
  img {
    width: 100%;
  }
  .join-content {
    width: 100%;
    padding: 30px 150px;
    .el-input {
      width: 400px;
      margin-right: 20px;
    }
    /deep/ .el-input__inner {
      width: 400px;
    }
  }
  .sendCode {
    width: 112px;
  }
  .agreement {
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    input {
      margin-right: 3px;
    }
  }
  .tips {
    color: red;
    font-size: 12px;
    margin: 0;
    text-align: center;
  }
}
</style>
